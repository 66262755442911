<template>
  <div class="project-execution">
    <el-table
      :data="tableData"
      class="customize-el-table custom-talbe"
      style="width: 100%;"
      :span-method="objectSpanMethod"
      :row-class-name="tableRowClassName"
      @cell-mouse-enter="handleCellMouseEnter"
      @cell-mouse-leave="handleCellMouseLeave"
    >
      <el-table-column
        prop="orgName"
        label="组织名称"
        align="center"
        class-name="org-name"
      />
      <el-table-column
        prop="projectTargetType"
        label="项目内容"
        align="center"
      >
        <template slot-scope="{row}">
          {{ row.projectTargetType === 1?'观念覆盖':'播客培养' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="targetUserCount"
        label="目标客户数"
        align="center"
      />
      <el-table-column
        prop="usedBudget"
        label="支出/预算"
        align="center"
      >
        <template slot-scope="{row}">
          {{ row.usedBudget }}/{{ row.budget }}
        </template>
      </el-table-column>
      <el-table-column
        prop="achievementRate"
        label="达成率"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-tooltip
            popper-class="customize-el-tooltip"
            placement="right"
            :open-delay="300"
            effect="light"
          >
            <template slot="content">
              已执行：{{ row.executedCount }}<span style="margin-right: 10px;" />项目推广目标：{{ row.targetCount }}
            </template>
            <span style="display: flex; align-items: center; gap: 10px;">
              <el-progress
                style="flex: 1;"
                color="#30C691"
                :stroke-width="8"
                :show-text="false"
                define-back-color="#E1E1E1"
                :percentage="row.achievementRate > 100 ? 100: row.achievementRate"
              />
              <span v-if="row.achievementRate" style="width: 35px; text-align: right;">{{ row.achievementRate }}%</span>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="targetScoreVOS"
        label="医生得分"
        width="150"
        align="center"
        class-name="chart-column"
      >
        <template v-if="!!(!row.targetScore && !row.targetScoreVOS.length)" slot-scope="{row}">
          <Chart
            :title-text="chartBind(row).titleText"
            :y-data="chartBind(row).yData"
            :x-data="chartBind(row).xData"
            :ref-key="chartBind(row).refKey"
            :item-stylye-color="chartBind(row).itemStylyeColor"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  name: 'ProjectExecution',
  components: {Chart},
  data() {
    return {
      searchParams: {
        projectId: this.$route.query.id
      },
      tableData: [],
      spanArr: [],
      currentIndex: ''
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      try {
        const params = {...this.searchParams}
        const {data} = await this.$axios.get(this.$API.orgExecData, {params})
        this.tableData = this.dealData(data)
        this.rowspan()
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      }
    },
    rowspan() {
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr.push(1)
          this.position = 0
        } else {
          if (this.tableData[index].orgId === this.tableData[index - 1].orgId) {
            this.spanArr[this.position] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.position = index
          }
        }
      })
    },
    objectSpanMethod({rowIndex, columnIndex }) {  // 表格合并行
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    dealData(data) {
      return data.map(item => {
        return item.targetTypeVOS?.map(targetTypeVO => {
          return {
            ...item,
            ...targetTypeVO,
            rowspan: item.targetTypeVOS?.length || 0
          }
        })
      }).flat(2).filter(v => v)
    },

    chartBind(row) {
      const {projectTargetType, targetScore = null, targetScoreVOS = []} = row
      const obj = {
        refKey: `${row.projectId}_${ row.projectTargetType}`,
        xData: this.getChartData(targetScoreVOS, 'dateStr'),
        yData: this.getChartData(targetScoreVOS, 'targetScore')
      }
      if (projectTargetType === 1) {
        obj.titleText = ![0, null].includes(targetScore) ? `观念得分:${targetScore}` : ''
        obj.itemStylyeColor = '#3D61E3'
      } else if (projectTargetType === 2) {
        obj.titleText = ![0, null].includes(targetScore) ? `行为得分:${targetScore}` : ''
        obj.itemStylyeColor = '#AE3DE3'
      }
      return obj
    },
    getChartData(targetScoreVOS, type) {
      return targetScoreVOS.length ? targetScoreVOS.map(item => item[type]) : []
    },
    handleCellMouseEnter(row) { // 鼠标移入后赋值
      this.currentIndex = row.orgId
    },
    handleCellMouseLeave() { // 鼠标移走后置空
      this.currentIndex = ''
    },

    // 行的颜色设置
    tableRowClassName({ row }) {
      const flag = row?.orgId == this.currentIndex
      return flag ? 'quotatemplate-my-hover-row' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.project-execution {
  ::v-deep .org-name {
    border-right: 1px solid #ebeef5;
  }
  ::v-deep .custom-talbe {
    .quotatemplate-my-hover-row {
      background: #f4f6fa !important;
    }
    &.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: transparent;
    }
    .chart-column {
      padding: 0;
      .cell {
        padding: 0;
      }
    }
  }
}
</style>

<template>
  <div class="conceptual-overlay">
    <div class="header-controls">
      <span class="left">
        <template v-if="!(!detail.isProjectCreator && !detail.isProjectExecOrg)">
          <span>观念覆盖目标：{{ projectExecDataVOS.targetCount }}次推广</span>
          <span>进行中：{{ projectExecDataVOS.onGoingCount }}次</span>
          <span>已完成：{{ projectExecDataVOS.finishedCount }}次</span>
        </template>
      </span>
      <div class="right">
        <el-button
          v-if="detail.isProjectCreator"
          type="info"
          class="create-btn"
          plain
          @click="visible = true"
        >
          添加活动 <svg-icon name="el-icon-plus" />
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      class="customize-el-table"
      style="width: 100%;"
    >
      <el-table-column
        prop="sceneTitle"
        label="活动名称"
        align="center"
      >
        <template slot-scope="{row}">
          <el-link
            :type="linkUrl(row)?'primary':''"
            :underline="false"
            target="_blank"
            :href="linkUrl(row)"
          >
            {{ row.sceneTitle }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="sceneTypeDesc"
        label="活动类型"
        align="center"
      />
      <el-table-column
        prop="activityStatusDesc"
        label="活动状态"
        align="center"
      />
      <el-table-column
        prop="knowledgePointNames"
        label="关键信息"
        align="center"
      >
        <template slot-scope="{row}">
          <div
            v-for="(item, index) in (row.knowledgePointNames || []).slice(0,3)"
            :key="item"
            :title="item"
            style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
          >
            {{ index + 1 }}.{{ item }}
          </div>
          <svg-icon v-if="(row.knowledgePointNames || []).length > 3" name="el-icon-more" />
        </template>
      </el-table-column>
      <el-table-column
        :prop="!detail.isProjectCreator && !detail.isProjectExecOrg ? '':'onGoingCount'"
        label="进行中"
        align="center"
      />
      <el-table-column
        :prop="!detail.isProjectCreator && !detail.isProjectExecOrg ? '':'completedCount'"
        label="已完成"
        align="center"
      />
      <el-table-column
        prop="orgName"
        label="来源"
        align="center"
      />
      <el-table-column
        label="操作"
        width="150"
        align="center"
      >
        <template slot-scope="{row}">
          <el-button type="text" @click="distribution(row)">分配</el-button>
          <el-button type="text" @click="revoke(row)">撤回</el-button>
          <el-button
            v-if="detail.isProjectCreator"
            type="text"
            :style="`color: ${row.activityStatus === 0?'#3D61E3':'#e8312f'};`"
            @click="switchActivityStatus(row)"
          >
            {{ row.activityStatus === 0? '启用':'停用' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 20px;"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      :small="pagination.small"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
    <DetailActiveDialog v-model="visible" :parent-data-list-fn="getDataList" />
    <!-- 撤回弹窗 -->
    <RecallSceneCodeModal
      :visible="isShowRecallPop"
      :scene-id="activityRow.sceneId"
      :is-self-buy="detail.isProjectCreator"
      :tabs-list="recallSceneTabs"
      :scene-type="getSceneRule('value',activityRow.sceneType).label"
      :project-id="$route.query.id"
      @onClose="onCloseModal"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import DetailActiveDialog from './detail-active-dialog.vue'
import RecallSceneCodeModal from '@/bizComponents/RecallSceneCodeModal'
export default {
  name: 'ConceptualOverlay',
  components: {
    DetailActiveDialog,
    RecallSceneCodeModal
  },
  mixins: [paginationMixin],
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      tableData: [],
      searchParams: {
        projectId: this.$route.query.id
      },
      visible: false,
      isShowRecallPop: false,

      sceneRuleType: [{
        label: 'meeting',
        desc: '科会',
        value: 1
      }, {
        label: 'questionnaire',
        desc: '调研/病例',
        value: 4
      }, {
        label: 'live',
        desc: '直播',
        value: 5
      }, {
        label: 'webinar',
        desc: '会议',
        value: 6
      }, {
        label: 'academicVisit',
        desc: '拜访',
        value: 8
      }, {
        label: 'training',
        desc: '代表培训',
        value: 7
      }],
      activityRow: {},
      recallSceneTabs: [
        {
          label: '代表未使用',
          name: 'represent',
          show: ''
        },
        {
          label: '定向任务',
          name: 'doctor',
          show: ''
        }
      ]
    }
  },
  computed: {
    projectExecDataVOS() {
      return this.detail.projectExecDataVOS.find(item => item.projectTargetType === 1) || {}
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.searchParams)
        const {data: {data, totalCount}} = await this.$axios.get(this.$API.conceptualCoverageList, {params})
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    },
    async distribution(row) {
      if (row.isInTrash) {
        await this.$confirm('活动已被放入回收站，无法分配！', '提示', {
          showCancelButton: false,
          showClose: false,
          confirmButtonText: '我知道了',
          closeOnClickModal: false,
          type: 'warning'
        })
        return false
      }
      this.$router.push(`/academic/scene/${this.getSceneRule('value', row.sceneType).label}/rule/${row.sceneId}?disabledBack=${!row.canEdit}`)
    },
    getSceneRule(key, value) {
      return this.sceneRuleType.find(item => item[key] === value) || {}
    },
    async revoke(row) {
      if (row.isInTrash) {
        await this.$confirm('活动已被放入回收站，无法撤回！', '提示', {
          showCancelButton: false,
          showClose: false,
          confirmButtonText: '我知道了',
          closeOnClickModal: false,
          type: 'warning'
        })
        return false
      }
      this.activityRow = row
      this.$nextTick(() => {
        this.isShowRecallPop = true
      })
    },
    onCloseModal() {
      this.isShowRecallPop = false
      this.$nextTick(() => {
        this.activityRow = {}
      })
    },
    linkUrl(row = []) {
      return row.videoUrl?.filter(item => item.includes('.mp4'))[0]
    },
    async switchActivityStatus(row) {
      try {
        if (row.activityStatus === 1) {
          await this.$MessageDialog({
            message: '请确认是否停用该活动，一经停用下级组织不可使用该项目'
          })
        }
        const params = {
          projectId: row.projectId,
          sceneId: row.sceneId,
          activityType: row.activityType,
          activityStatus: row.activityStatus === 1 ? 0 : 1
        }
        const {data} = await this.$axios.post(this.$API.stopOrUseActivity, params)
        console.log('data: ', data)
        if (data) {
          this.$msg.success('操作成功')
          this.getDataList()
        }
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      }
    }
  }
}
</script>


<template>
  <div class="step3">
    <el-form
      ref="form"
      label-suffix="："
      label-width="130px"
      label-position="right"
      :model="model"
      :rules="model.rules"
    >
      <el-form-item label="设置目标与预算" class="budget">
        <div class="header">
          <span v-for="item in form.budgetList" :key="item.projectTargetType">{{ getBudget('value', item.projectTargetType).label }}已分配/总预算：{{ getTotleMoney('orgProjectTargetBudget', item.projectTargetType) }}/{{ getTotleMoney('all', item.projectTargetType) }}</span>
        </div>
        <el-table
          v-loading="loading"
          :span-method="objectSpanMethod"
          :data="model.tableData"
          style="width: 800px;"
          class="customize-el-table custom-table"
          :row-class-name="tableRowClassName"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
        >
          <el-table-column class-name="project-name" prop="projectTargetTypeText" label="项目内容" />
          <el-table-column prop="representCount" label="执行团队人数" align="center" />
          <el-table-column prop="orgName" label="组织名称" align="center" />
          <el-table-column prop="customerCount" label="目标客户数" align="center" />
          <el-table-column
            label="项目预算（积分）"
            prop="orgProjectTargetBudget"
            width="120"
            align="center"
          >
            <template slot-scope="scoped">
              <el-form-item :prop="`tableData.${scoped.$index}.orgProjectTargetBudget`" :rules="model.rules.orgProjectTargetBudget">
                <el-input-number
                  :min="0"
                  :max="inputMaxNumber(scoped.row)"
                  step-strictly
                  :precision="0"
                  :step="1"
                  :value="scoped.row.orgProjectTargetBudget"
                  class="customize-el-input-number"
                  @input="updateExecOrgIds('orgProjectTargetBudget', scoped.row, $event)"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="执行场次（场）"
            width="120"
            prop="targetCount"
            align="center"
          >
            <template slot-scope="scoped">
              <el-form-item :prop="`tableData.${scoped.$index}.targetCount`" :rules="model.rules.targetCount">
                <el-input-number
                  :value="scoped.row.targetCount"
                  :step="1"
                  :precision="0"
                  step-strictly
                  :min="0"
                  class="customize-el-input-number"
                  @input="updateExecOrgIds('targetCount', scoped.row, $event)"
                />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <div class="remark">
          备注：组织设置了项目预算后，各组织在参与活动的时候，可在预算范围内开展相关学术活动
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Step3',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    budgetI18n: {
      type: Array,
      default: () => []
    },
    execOrgList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      spanArr: [],
      model: {
        tableData: [],
        rules: {
          orgProjectTargetBudget: [{ required: true, message: '项目预算不能为空', trigger: 'blur' }],
          targetCount: [{ required: true, message: '执行目标不能为空', trigger: 'blur' }]
        }
      },
      currentIndex: ''
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    async initTableData() {
      this.loading = true
      for (let i = 0; i < this.form.budgetList.length; i++) {
        const budget = this.form.budgetList[i]
        const targetCustomNumList = await this.orgRepCount({
          orgIds: budget.execOrgIds?.map(item => item.orgId) || [],
          doctorIds: budget.customerType === 2 ? budget.targetUserList?.map(item => item.id) || [] : []
        })
        budget.execOrgIds?.forEach(execOrg => {
          const row = targetCustomNumList?.find(item => item.factoryId === execOrg.orgId)
          if (row) {
            this.$set(execOrg, 'customerCount', row.doctorCount)
            this.$set(execOrg, 'representCount', row.representCount)
          }
        })
      }
      this.model.tableData = this.dealData(this.form.budgetList)
      this.rowspan()
      this.loading = false
    },
    dealData(val) {
      return val
        ?.map(budget => {
          const childs = budget.execOrgIds.map(execOrg => {
            return {
              ...execOrg,
              projectTargetType: budget.projectTargetType,
              projectTargetTypeText: this.getBudget('value', budget.projectTargetType).label
            }
          })

          if (childs?.length === 0 && budget.customerType === 1) {
            budget.projectTargetTypeText = this.getBudget('value', budget.projectTargetType).label
            budget.orgName = ''
            return budget
          }
          return childs
        })
        .flat(2)
        .filter(v => v)
    },
    updateExecOrgIds(targetKey, row, val) {
      this.form.budgetList.forEach(budget => {
        if (budget?.projectTargetType === row.projectTargetType) {
          budget?.execOrgIds?.forEach(execOrg => {
            if (execOrg.orgId === row.orgId) {
              this.$set(execOrg, targetKey, val)
            }
            return execOrg
          })
        }
        return budget
      })

      this.model.tableData = this.dealData(this.form.budgetList)
    },
    getTotleMoney(type, projectTargetType, orgId) {
      const budget = this.form.budgetList?.find(item => item.projectTargetType === projectTargetType)
      if (type === 'all') {
        return budget?.projectTargetBudget
      } else if (type === 'orgProjectTargetBudget') {
        const total = budget?.execOrgIds?.reduce((accumulator, item) => {
          return accumulator + (orgId === item.orgId ? 0 : item.orgProjectTargetBudget)
        }, 0)
        return total
      }
    },
    inputMaxNumber(row) {
      const total = this.getTotleMoney('all', row.projectTargetType)
      const otherVal = this.getTotleMoney('orgProjectTargetBudget', row.projectTargetType, row.orgId)

      const maxNum = total - otherVal
      return total ? maxNum : total
    },
    getBudget(key, value) {
      const row = this.budgetI18n.find(item => item[key] === value) ?? {}
      return row
    },
    async orgRepCount(params) {
      try {
        if (params.orgIds.length === 0) return
        const { data } = await this.$axios.post(this.$API.factoryDoctorRepresentCount, params)
        return data
      } catch (error) {
        console.log('error: ', error)
      }
    },
    rowspan() {
      this.model.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr.push(1)
          this.position = 0
        } else {
          if (this.model.tableData[index].projectTargetType === this.model.tableData[index - 1].projectTargetType) {
            this.spanArr[this.position] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.position = index
          }
        }
      })
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      // 表格合并行
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    submit() {
      return new Promise((resove, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resove(this.form)
          } else {
            reject(this.form)
          }
        })
      })
    },
    handleCellMouseEnter(row) { // 鼠标移入后赋值
      this.currentIndex = row.projectTargetType
    },
    handleCellMouseLeave() { // 鼠标移走后置空
      this.currentIndex = ''
    },

    // 行的颜色设置
    tableRowClassName({ row }) {
      const flag = row?.projectTargetType == this.currentIndex
      return flag ? 'quotatemplate-my-hover-row' : ''
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.step3 {
  ::v-deep .budget {
    .header {
      font-weight: bold;
      font-size: 12px;
      color: #0f0f0f;
      display: flex;
      gap: 40px;
      margin-bottom: 15px;
    }
    .custom-table {
      .project-name {
        border-right: 1px solid #ebeef5;
      }
      .quotatemplate-my-hover-row {
        background: #f4f6fa !important;
      }
      &.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
        background: transparent;
      }
    }
    .remark {
      font-size: 12px;
      color: #c1c5c9;
      margin-top: 10px;
    }
  }
}
</style>

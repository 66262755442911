<template>
  <div :ref="refKey" class="stategic-chart" />
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Chart',
  props: {
    titleText: {
      type: String,
      default: ''
    },
    itemStylyeColor: {
      type: String,
      default: '#3D61E3'
    },
    xData: {
      type: Array,
      default: () => []
    },
    yData: {
      type: Array,
      default: () => []
    },
    refKey: {
      type: String,
      default: 'stategic-chart-ref'
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  computed: {
    sourceData() {
      return {
        titleText: this.titleText,
        xData: this.xData,
        yData: this.yData
      }
    },
    areaStyleColor() {
      const {r, g, b} = this.hexToRgb(this.itemStylyeColor)
      return [`rgba(${r}, ${g}, ${b},0.5)`, `rgba(${r}, ${g}, ${b},0)`]
    }
  },
  beforeDestroy() {
    this.myChart?.clear()
    this.myChart?.dispose()
    this.myChart = null
  },
  mounted() {
    this.$nextTick(() => {
      this.chartDom = this.$refs[this.refKey]
      const {xData, yData} = this.sourceData
      this.initChart(xData, yData)
    })
  },
  methods: {
    initChart(xData = this.xData, yData = this.yData) {
      if (!this.myChart) {
        this.myChart = echarts.init(this.chartDom)
      } else {
        this.myChart.resize()
      }
      const option = {
        title: [{
          left: 'center',
          text: this.titleText,
          textStyle: {
            fontSize: 11,
            fontWeight: 400,
            color: '#0F0F0F',
            top: 0
          }
        }],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData,
          splitLine: { show: false},
          axisLine: { show: false }, // 控制轴线是否显示
          axisTick: { show: false }, // 控制轴线刻度是否显示
          axisLabel: { show: false } // 控制轴标签即文字是否显示
        },
        yAxis: {
          type: 'value',
          boundaryGap: ['0', '100%'],
          splitLine: { show: false},
          axisLine: { show: false }, // 控制轴线是否显示
          axisTick: { show: false }, // 控制轴线刻度是否显示
          axisLabel: { show: false } // 控制轴标签即文字是否显示
        },
        grid: {
          left: '0',
          top: '14px',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        series: [
          {
            type: 'line',
            symbol: xData.length === 1 ? 'circle' : 'none',
            sampling: 'lttb',
            smooth: true,
            itemStyle: {
              color: this.itemStylyeColor
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: this.areaStyleColor[0]
                },
                {
                  offset: 1,
                  color: this.areaStyleColor[1]
                }
              ])
            },
            data: yData
          }
        ]
      }

      if (!(xData.length && yData.length)) {
        option.title.push({
          subtext: '暂无数据',
          top: 'center',
          left: 'center',
          itemGap: this.titleText ? 10 : 0,
          textStyle: {
            align: 'center'
          },
          subtextStyle: {
            fontSize: 12
          }
        })
      }
      this.$nextTick(() => {
        option && this.myChart.setOption(option)
      })
    },
    hexToRgb(hex) {
      // 去除前缀 # 号
      hex = hex.replace('#', '')

      // 将十六进制色值分为三个部分
      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)
      // 返回分解后的RGB值
      return {r, g, b}
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.stategic-chart {
  width: 150px;
  min-height: 65px;
}
</style>

<template>
  <el-dialog
    class="customize-el-dialog"
    :title="title"
    v-bind="$attrs"
    :close-on-click-modal="$attrs['close-on-click-modal'] || false"
    :visible.sync="dialogFormVisible"
    v-on="$listeners"
  >
    <div class="steps-box">
      <div>
        <div class="out-line-left" :class="{ 'is-already': isUpload }" />
        <div class="pdg-t10" :class="{ 'is-already-text': isUpload }">
          1.上传Excel
        </div>
      </div>
      <div>
        <div class="out-line-center" :class="{ 'is-already': isCheck }" />
        <div class="pdg-t10" :class="{ 'is-already-text': isCheck }">
          2.检验数据
        </div>
      </div>
      <div>
        <div class="out-line-right" :class="{ 'is-already': isImport }" />
        <div class="pdg-t10" :class="{ 'is-already-text': isImport }">
          3.导入数据
        </div>
      </div>
    </div>
    <!-- 上传Excel -->
    <div v-show="isUpload && !isCheck && !isImport" class="upload-content-box">
      <div class="upload-model-box">
        <span>上传文件：</span>
        <div>
          <el-upload
            :action="uploadApi"
            :headers="headers"
            name="file"
            :data="uploadData"
            :show-file-list="false"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls"
            list-type="picture-card"
            :on-progress="onUploadProgress"
            :on-success="onUploadSuccess"
            :on-error="onUploadError"
          >
            <template>
              <div class="upload-cover">
                <img v-if="uploadFlag" :src="imageUrl" alt="">
                <img
                  v-else-if="isSuccess"
                  :src="require('@/assets/iconImg/success.png')"
                  alt=""
                >
                <img
                  v-else
                  :src="require('@/assets/iconImg/fail.png')"
                  alt=""
                >
                <span>{{ uploadText }}</span>
              </div>
            </template>
          </el-upload>
          <div v-show="uploadProgress" class="out-progress">
            <div class="in-progress" :style="{ width: uploadProgress + '%' }" />
          </div>
        </div>
      </div>
      <div class="remark">
        <span>请</span>
        <el-link type="primary" @click="onDownModal">点击此处</el-link>
        <span>下载{{ title }}模板，填写后完成上传文件</span>
      </div>
    </div>
    <!-- 检测数据 -->
    <div v-show="isUpload && isCheck && !isImport">
      <div v-if="!isCheckPass" class="check-info-box">
        <div style="text-align: center;">
          <img
            :src="require('@/assets/common/icon_failed.png')"
            alt=""
            style="width: 50px; margin-bottom: 25px;"
          >
        </div>
        <span>共导入{{ responseObj.totalCount || 0 }}条数据，其中{{
          responseObj.successCount || 0
        }}条有效数据校验成功，{{
          responseObj.errorCount || 0
        }}条有效数据校验失败。请</span>
        <el-button type="text" size="mini" @click="onDownError">
          下载文件
        </el-button>
        <span>查看失败原因，纠查后重新上传校验。</span>
        <template v-if="helpUrl">
          <span>如有疑问，请</span>
          <el-button type="text" size="mini" @click="onJumpHelp">
            点击此处
          </el-button>
          <span>查看解决方法。</span>
        </template>
      </div>
      <div v-else class="check-info-box">
        <div style="text-align: center;">
          <img
            :src="require('@/assets/common/icon_success.png')"
            alt=""
            style="width: 50px; margin-bottom: 25px;"
          >
        </div>
        <span>共导入{{ responseObj.totalCount }}条数据，其中{{
          responseObj.successCount
        }}条有效数据校验成功，{{
          responseObj.errorCount
        }}条有效数据校验失败。</span>
        <div class="vHtml" v-html="tipContent" />
      </div>
    </div>
    <!-- 导入数据 -->
    <div v-show="isUpload && isCheck && isImport" class="check-info-box">
      <img
        src="@/assets/common/icon_success.png"
        alt=""
        style="width: 50px; margin-bottom: 25px;"
      >
      <div>{{ title }}成功</div>
      <div>共{{ responseObj.successCount }}条数据</div>
    </div>
    <div
      v-show="isUpload && isCheck && !isImport"
      slot="footer"
      class="dialog-footer upload-btn-box"
    >
      <el-button v-if="!isCheckPass" type="primary" @click="onBackSteps">
        重新上传校验
      </el-button>
      <el-button
        v-else
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >
        {{ btnText }}
      </el-button>
    </div>
    <div
      v-show="isUpload && isCheck && isImport"
      slot="footer"
      class="dialog-footer"
    >
      <el-button type="primary" @click="onComplete">完成</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getToken } from '@/utils/auth'
export default {
  name: 'CommonImportExcelDialog',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    btnText: {    // 按钮文案
      type: String,
      default: '开始导入'
    },
    title: {
      type: String,
      default: '批量导入'
    },
    uploadApi: {  // 上传Excel接口
      type: String,
      default: ''
    },
    helpUrl: {    // 帮助中心地址
      type: String,
      default: ''
    },
    downFn: {   // 自定义下载模板函数, 与下载模版类型互斥
      type: Function,
      default: null
    },
    downType: {   // 下载模板类型
      type: Number,
      default: null
    },
    confirmApi: {    // 确认上传导入的Excel的接口
      type: String,
      default: ''
    },
    confirmParams: {
      type: Object,
      default() {
        return {}
      }
    },
    uploadData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      headers: {
        token: getToken()
      },
      isUpload: true,
      isCheck: false,
      isCheckPass: false,
      isImport: false,
      uploadFlag: true,
      isSuccess: false,
      uploadProgress: 0,
      responseObj: {},
      file: null,
      loading: false,
      uploadedList: []
    }
  },
  computed: {
    dialogFormVisible: {
      get() {
        if (this.value) {
          this.onResetModel()
        }
        return this.value
      },
      set(val) {
        if (this.loading) return true
        this.$emit('input', val)
      }
    },
    imageUrl() {
      return require('@/assets/common/file_excel.png')
    },
    uploadText() {
      return '上传文件'
    },
    tipContent() {
      return this.responseObj.tipContent || '若无异议，请点击“开始导入”'
    }
  },
  methods: {
    // ---------- 工具 ------------------
    onUploadConfirm() {
      //  导入表格确认
      if (this.loading) return
      this.loading = true
      const params = {
        dataId: this.responseObj.dataId || '',
        ...this.confirmParams
      }
      this.$axios.get(this.confirmApi, {params}).then(
        res => {
          this.loading = false
          this.isImport = true
          this.$emit('onConfirm', res)
        },
        rea => {
          this.loading = false
          this.$message({
            message: rea.message,
            type: 'error'
          })
        }
      )
    },
    // ----------------------------------
    onResetModel() {
      Object.assign(this.$data, this.$options.data())
    },
    onJumpHelp() {
      // 跳转帮助中心
      let url = ''
      if (this.helpUrl) {
        url = this.$router.resolve(this.helpUrl)
      }
      window.open(url.href, '_blank')
    },
    onUploadProgress(event, file) {
      // 文件本地上传进度
      this.uploadFlag = true
      this.$nextTick(() => {
        this.uploadProgress = file.percentage.toFixed(0) * 1
      })
    },
    onUploadSuccess(response, file) {
      // 校验结果
      if (response.data) {
        this.isSuccess = true
        this.uploadFlag = false
        this.isCheck = true
        this.responseObj = response.data
        this.isCheckPass = !(response.data.errorCount > 0)
        this.file = file.raw
      } else {
        this.$message({
          message: response.message,
          type: 'error'
        })
        this.onUploadError()
      }
    },
    onUploadError() {
      // 校验错误显示
      this.isSuccess = false
      this.uploadFlag = false
      this.isCheckPass = false
    },
    onDownModal() {
      if (this.downFn) {
        this.downFn()
      } else {
        this.onCommonDownModel()
      }

    },
    onCommonDownModel() {
      // 通用下载模板
      this.$axios.get(this.$API.importCommonDownModel, {params: {dataType: this.downType}}).then(
        res => {
          const {data: {downloadUrl, noticeContent}} = res
          if (downloadUrl) {
            window.open(downloadUrl)
          } else {
            this.$message.success(noticeContent)
          }
        },
        rea => {
          this.$message({
            message: rea.message,
            type: 'error'
          })
        }
      )
    },
    onDownError() {
      // 下载失败模板
      if (this.responseObj.errorExcelUrl) {
        window.open(this.responseObj.errorExcelUrl)
      }
    },
    onBackSteps() {
      // 重新上传
      this.isUpload = true
      this.isImport = false
      this.isSuccess = false
      this.uploadFlag = true
      this.isCheck = false
      this.responseObj = {}
      this.isCheckPass = false
      this.file = null
      this.loading = false
      this.uploadProgress = 0
    },
    onConfirm() {
      // 导入表格确认
      if (!this.responseObj?.successCount) {
        this.onCloseModel()
        return this.$message.error('请按模板填写有效数据')
      }
      this.onUploadConfirm()

    },
    onCloseModel() {
      // 完成
      this.dialogFormVisible = false
    },
    onComplete() {
      this.$emit('onComplete', this.responseObj)
      this.onCloseModel()
    }
  }
}
</script>
<style lang="scss">
.upload-model-box .el-upload--picture-card {
  width: 160px;
  height: 90px;
}
.upload-btn-box .el-upload--picture-card {
  width: auto;
  height: auto;
  line-height: inherit;
  border: none;
}
</style>
<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  text-align: center;
}
.steps-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.out-line-left {
  border-radius: 100px 0 0 100px;
}
.out-line-left,
.out-line-center,
.out-line-right {
  width: 140px;
  height: 10px;
  background-color: #d8d8d8;
  margin: 0 5px;
  @media screen and (max-width: 768px) {
    width: 80px;
  }
}
.out-line-right {
  border-radius: 0 100px 100px 0;
}
.is-already {
  background-color: #3d61e3;
}
.is-already-text {
  color: #3d61e3;
}
.upload-content-box {
  padding: 50px;
  width: fit-content;
  margin: auto;
  color: #5a5a5a;
}
.remark {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909399;
  margin-top: 20px;
}
.upload-model-box {
  display: flex;
  padding-top: 16px;
  flex-wrap: wrap;
}
.upload-cover {
  width: 100%;
  height: 100%;
  background-color: #e1e1e1;
  color: #787878;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  img {
    width: 30px;
  }
  span {
    height: 30px;
    line-height: 30px;
  }
}
.out-progress {
  width: 160px;
  height: 6px;
  margin-top: 10px;
  background: #e1e1e1;
  border-radius: 20px;
  overflow: hidden;
}
.in-progress {
  height: 100%;
  background: #30c691;
  border-radius: 20px;
}
.check-info-box {
  padding: 60px 100px;
  color: #0f0f0f;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 60px 20px;
  }
}
.vHtml {
  color: #0f0f0f;
  text-align: center;
  margin-top: 10px;
}
.import-info {
  text-align: center;
  padding: 50px;
}
.pdg-t10 {
  padding-top: 10px;
}
.mgn-r20 {
  margin-right: 20px;
}
</style>

<template>
  <div class="speaker-training">
    <div class="header-controls">
      <span class="left">
        <template v-if="!(!detail.isProjectCreator && !detail.isProjectExecOrg)">
          <span>播客培养目标：{{ projectExecDataVOS.targetCount }}次</span>
          <span>进行中：{{ projectExecDataVOS.onGoingCount }}次</span>
          <span>已完成：{{ projectExecDataVOS.finishedCount }}次</span>
        </template>
      </span>
      <div class="right">
        <el-dropdown
          v-if="detail.isProjectExecOrg || detail.isProjectCreator"
          size="medium"
          trigger="click"
          @command="handleCommand"
        >
          <el-button
            type="info"
            plain
            class="create-btn"
          >
            创建活动 <svg-icon name="el-icon-plus" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="CreateLive">创建直播</el-dropdown-item>
            <el-dropdown-item command="CreateWebinar">创建会议</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-table
      :data="tableData"
      class="customize-el-table"
      style="width: 100%;"
    >
      <el-table-column
        prop="sceneTitle"
        label="活动名称"
        align="center"
      >
        <template slot-scope="{row}">
          <el-link
            :type="row.downloadUrl?'primary':''"
            :underline="false"
            target="_blank"
            :href="row.downloadUrl"
          >
            {{ row.sceneTitle }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="sceneTypeDesc"
        label="活动类型"
        align="center"
      />
      <el-table-column
        prop="speaker"
        label="播客"
        align="center"
      />
      <el-table-column
        prop="orgGradeIdDesc"
        label="播客企业级别"
        align="center"
      />
      <el-table-column
        prop="knowledgePointNames"
        label="关键信息"
      >
        <template slot-scope="{ row }">
          <div
            v-for="(item, index) in (row.knowledgePointNames || []).slice(0,3)"
            :key="item"
            :title="item"
            style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
          >
            {{ index + 1 }}.{{ item }}
          </div>
          <svg-icon v-if="(row.knowledgePointNames || []).length > 3" name="el-icon-more" />
        </template>
      </el-table-column>
      <el-table-column
        prop="reviewStatusDesc"
        label="状态"
        align="center"
      />
      <el-table-column
        label="操作"
        width="150"
        align="center"
      >
        <template slot-scope="{row}">
          <el-button
            v-if="row.reviewStatus === 0"
            type="text"
            @click="submitAudit(row)"
          >
            提交审核
          </el-button>
          <el-link
            v-if="row.downloadUrl"
            type="primary"
            :url="row.downloadUrl"
            target="_blank"
            :underline="false"
            style="font-size: 12px; margin-left: 10px;"
          >
            查看
          </el-link>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 20px;"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      :small="pagination.small"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'SpeakerTraining',
  mixins: [paginationMixin],
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tableData: [],
      searchParams: {
        projectId: this.$route.query.id
      }
    }
  },
  computed: {
    projectExecDataVOS() {
      return this.detail.projectExecDataVOS.find(item => item.projectTargetType === 2)
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async submitAudit(row) {
      console.log('row: ', row)
      try {
        const params = {
          liveId: row.sceneId,
          doctorId: row.doctorId
        }
        const {data} = await this.$axios.get(this.$API.onReviewLiveAccount, {params})
        if (data) {
          this.$msg.success('提交审核成功')
          this.getDataList()
        }
      } catch (error) {
        this.$msg.error(error.message)
      }
    },
    handleCommand(name) {
      // 获取目标路由的 URL
      const routePath = this.$router.resolve({
        name: name
      }).href
      // 在新标签页中打开该 URL
      window.open(routePath, '_blank')
    },
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.searchParams)
        const { data: { data, totalCount } } = await this.$axios.get(this.$API.speakerTrainingList, {params})
        this.tableData = data || []
        this.pagination.total = totalCount || 0
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

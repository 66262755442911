<template>
  <el-dialog
    class="customize-el-dialog add-customer"
    title="添加客户"
    width="840px"
    :visible="dialogVisible"
    v-bind="$attrs"
    v-on="$listeners"
    @close="handleClose"
    @open="handleOpen"
  >
    <div class="header">
      <el-input
        v-model="searchParams.searchKey"
        v-debounce="[onSearch, 'input']"
        prefix-icon="el-icon-search"
        class="reset-item"
        :placeholder="`搜索${projectTargetType === 1 ? '医生' : '播客'}姓名`"
        clearable
        @clear="onSearch"
        @keydown.enter.native="onSearch"
      />
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      class="customize-el-table"
      height="300px"
      style="width: 100%; margin-top: 10px;"
      :row-key="(row) => row.id"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" reserve-selection width="55" />
      <el-table-column
        prop="userName"
        label="姓名"
        align="center"
      />
      <el-table-column
        prop="titleName"
        label="所属职称"
        align="center"
      />
      <el-table-column
        prop="hospital"
        label="医院"
        align="center"
      />
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 20px;"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      :small="pagination.small"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />

    <div slot="footer" style="margin-top: 20px;">
      <el-button type="primary" plain @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import {uniqBy} from 'lodash-es'
export default {
  name: 'AddCustomer',
  mixins: [paginationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    projectTargetType: {
      type: [String, Number],
      default: ''
    },
    actBudget: {
      type: Object,
      default() {
        return {}
      }
    },
    strategyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      searchParams: {
        doctorName: '',
        searchKey: '',
        strategyId: this.strategyId,
        includeDelegation: false
      },
      tableData: [],
      multipleSelection: []
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    formData: {
      get() {
        return this.actBudget
      },
      set(val) {
        this.$emit('update:form', val)
      }
    }
  },
  methods: {
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.searchParams)
        let url = this.$API.getDoctorListV2
        if (this.projectTargetType === 2) {
          url = this.$API.getSpeakerList
          params.doctorName = params.searchKey
          delete params.searchKey
          delete params.includeDelegation
        }
        // 修改 this.$API.getDoctorListV2是post请求，this.$API.getSpeakerList是get请求
        const res = this.projectTargetType === 2 ? await this.$axios.get(url, {params}) : await this.$axios.post(url, params)
        const {
          data: { data, totalCount }
        } = res
        this.tableData = (this.projectTargetType === 2 ? res.data : data).map(item => {
          item.userName = item.doctorName || item.name
          item.id = item.id || item.doctorId
          item.departmentName = item.department || item.departmentName
          item.representItemList = item.representItemList || item.representInfoList
          return item
        })
        if (this.projectTargetType === 2) {
          this.pagination.total = res.count || 0
        } else {
          this.pagination.total = totalCount || 0
        }
        this.multipleSelection.forEach(item => {
          this.$refs.multipleTable.toggleRowSelection(item, true)
        })
      } catch (error) {
        console.log(error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    },
    handleOpen() {
      this.multipleSelection = this.formData.targetUserList
      this.getDataList()
    },
    handleClose() {
      this.$refs.multipleTable.clearSelection()
      this.dialogVisible = false
    },
    onSubmit() {
      const formatData = this.multipleSelection
      this.$set(this.formData, 'targetUserList', formatData)
      this.dialogVisible = false
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = uniqBy(val, 'id')
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.add-customer {
  .header {
    ::v-deep .reset-item {
      width: 200px;
      .el-input__inner {
        border-radius: 30px;
      }
    }
  }
}
</style>

<template>
  <el-dialog
    :visible.sync="visible"
    title="添加活动"
    width="610px"
    append-to-body
    class="customize-el-dialog detail-active"
    @open="getDataList"
    @close="onCloseModal"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      size="mini"
      inline
    >
      <el-form-item>
        <el-input
          v-model="form.sceneTitle"
          v-debounce="[onSearch, 'input']"
          placeholder="搜索活动名称"
          class="customize-el-input"
          prefix-icon="el-icon-search"
          clearable
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.isCreator" placeholder="项目来源" @change="onSearch">
          <el-option label="我的项目" :value="true" />
          <el-option label="下级项目" :value="false" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.sceneType" placeholder="活动类型" @change="onSearch">
          <el-option
            v-for="item in activityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      height="370px"
      :data="tableData"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" :reserve-selection="true" width="55" />
      <el-table-column
        prop="sceneTitle"
        label="活动名称"
      />
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      style="margin-top: 20px;"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      :small="pagination.small"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />

    <div slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
export default {
  name: 'DetailActive',
  mixins: [paginationMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    parentDataListFn: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        projectId: this.$route.query.id,
        sceneType: 1,
        isCreator: true
      },
      activityList: [
        {value: 1, label: '科会'},
        {value: 4, label: '调研/病例'},
        {value: 5, label: '直播'},
        {value: 6, label: '会议'}
      ],
      loading: false,
      tableData: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {

  },
  methods: {
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.form)
        const { data: { data, totalCount } } = await this.$axios.post(this.$API.orgActiveBaseInfoList, params)
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        console.log(error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.form.sceneIds = []
      this.getDataList()
    },
    handleSelectionChange(data) {
      this.form.sceneIds = data.map(item => item.sceneId)
    },
    onCloseModal() {
      this.$refs.multipleTable.clearSelection()
    },
    async onSubmit() {
      try {
        const params = {
          sceneType: this.form.sceneType,
          sceneIds: this.form.sceneIds,
          projectId: this.form.projectId
        }
        const {data} = await this.$axios.post(this.$API.addConceptualCoverageActivity, params)
        if (data) {
          this.visible = false
          this.parentDataListFn()
          this.$msg.success('操作成功')
        }
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      }
    }
  }
}
</script>

<template>
  <div class="step1">
    <el-form
      ref="form"
      label-suffix="："
      :model="form"
      :rules="formRules"
      hide-required-asterisk
      label-width="130px"
    >
      <el-form-item label="项目名称" prop="projectName">
        <el-input
          v-model="form.projectName"
          :disabled="form.projectStatus !== -1 && !!$route.query.id"
          maxlength="40"
          show-word-limit
          style="width: 590px;"
          placeholder="请输入项目名称"
        />
      </el-form-item>
      <el-form-item label="项目描述" prop="projectDesc">
        <RichEditor
          v-model="form.projectDesc"
          placeholder="请输入项目描述"
          style="width: 790px;"
        />
      </el-form-item>
      <el-form-item
        label="项目内容与预算"
        class="form-container"
        prop="budgetList"
      >
        <el-checkbox-group :value="checkBox" @input="onChange">
          <div v-for="item in budgetI18n" :key="item.value">
            <el-checkbox :label="item.value">
              {{ item.label }}
            </el-checkbox>
            <span :key="1" :class="!checkBox.includes(item.value)? 'disabled':''">
              预算：<el-input-number
                :value="getBudget('projectTargetType',item.value).projectTargetBudget"
                :disabled="!checkBox.includes(item.value)"
                class="customize-el-input-number"
                size="mini"
                :min="0"
                :step="1"
                :precision="0"
                step-strictly
                @input="editBudget(item.value, $event)"
              /> 积分
            </span>
          </div>
        </el-checkbox-group>
        <div class="remark">观念覆盖：系统将会生成该批客户的观念得分，播客培养：将会为您统计该批客户的行为得分</div>
      </el-form-item>
      <el-form-item label="策略名称" prop="strategyId">
        <el-select
          v-model="form.strategyId"
          :disabled="form.projectStatus !== -1 && !!$route.query.id"
          placeholder="请选择策略"
        >
          <el-option
            v-for="stategic in stategicList"
            :key="stategic.strategyId"
            :label="stategic.strategyName"
            :value="stategic.strategyId"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Step1',
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    budgetI18n: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      formRules: {
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
          { min: 1, max: 40, message: '长度在 1 到 40 个字符', trigger: 'blur' }
        ],
        projectDesc: [
          { required: true, message: '请输入项目描述', trigger: 'blur' }
        ],
        budgetList: [
          { required: true, message: '请选择项目内容与预算', trigger: 'blur' }
        ],
        strategyId: [
          { required: true, message: '请选择策略', trigger: 'blur' }
        ]
      },
      stategicList: []
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    checkBox() {
      return this.form.budgetList.map(item => item.projectTargetType) || []
    }
  },
  mounted() {
    this.getStategicList()
  },
  methods: {
    // 获取策略列表
    async getStategicList() {
      try {
        const params = {
          status: this.$route.query.id ? null : 1,
          pageNo: 1,
          pageSize: 999999
        }
        const { data: {
          data
        } } = await this.$axios.get(this.$API.strategyList, {params})
        this.stategicList = data ?? []
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      }
    },
    onChange(value) {
      this.form.budgetList = value.map(item => {
        const row = this.getBudget('projectTargetType', item)
        return {
          projectTargetType: row?.projectTargetType || item,
          projectTargetBudget: row?.projectTargetBudget || 0,
          execOrgIds: row?.execOrgIds || [],
          customerType: row.customerType || 1,
          targetUserList: row.targetUserList || []
        }
      })
    },
    editBudget(projectTargetType, value) {
      this.form.budgetList = this.form.budgetList.map(item => {
        if (item.projectTargetType === projectTargetType) {
          item.projectTargetBudget = value
        }
        return item
      })
    },
    getBudget(key, value) {
      const row = this.form.budgetList.find(item => item[key] === value) ?? {}
      return row
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(this.form)
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.step1 {
  .form-container {
    .el-checkbox-group {
      >div {
        display: flex;
        gap: 40px;
        .disabled {
          color: #e1e1e1;
        }
        >span {
          font-size: 14px;
          color: #5a5a5a;
        }
      }
    }
    .remark {
      font-size: 12px;
      color: #c1c5c9;
      margin-top: 10px;
    }
  }
}
</style>

<template>
  <div class="project-stategic">
    <page-main class="content customize-page-main">
      <el-tabs
        :value="tabsValue"
        class="customize-el-tabs"
        @tab-click="onChange"
      >
        <el-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :disabled="loading"
          :label="item.name"
          :name="item.key"
        />
        <div class="search-box">
          <el-form inline class="search-form">
            <el-form-item>
              <el-input
                v-model="searchParams.projectName"
                v-debounce="[onSearch, 'input']"
                class="customize-el-input reset-item"
                style="width: 180px;"
                clearable
                prefix-icon="el-icon-search"
                placeholder="搜索项目名称"
                @clear="onSearch"
                @keydown.enter.native="onSearch"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="searchParams.isQueryChild"
                class="reset-item"
                style="width: 110px;"
                placeholder="项目来源"
                clearable
                @change="onSearch"
              >
                <el-option label="我的项目" :value="false" />
                <el-option label="下级项目" :value="true" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="searchParams.productId"
                style="width: 110px;"
                placeholder="所有产品"
                class="reset-item"
                clearable
                @change="onSearch"
              >
                <el-option
                  v-for="product in productList"
                  :key="product.id"
                  :label="product.tagName"
                  :value="product.tagId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="searchParams.strategyIds"
                style="width: 160px;"
                placeholder="所有策略"
                class="reset-item"
                multiple
                collapse-tags
                @change="onSearch"
              >
                <el-option
                  v-for="stategic in stategicList"
                  :key="stategic.strategyId"
                  :label="stategic.strategyName"
                  :value="stategic.strategyId"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <div class="right-btn-wrapper">
            <el-button type="primary" icon="el-icon-plus" @click="$router.push({name: 'ProjectStaegicEditCreate'})">新建项目</el-button>
          </div>
        </div>
        <el-table
          v-loading="loading"
          :data="tableData"
          class="customize-el-table custom-table"
          :span-method="objectSpanMethod"
          style="width: 100%;"
          :highlight-current-row="false"
          :row-class-name="tableRowClassName"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
        >
          <el-table-column
            prop="projectName"
            label="项目名称"
          >
            <template v-if="row.projectName" slot-scope="{ row }">
              <div class="project-name">
                <span class="title">{{ row.projectName }}</span>
                <el-popover
                  placement="right-start"
                  :offset="-10"
                  :title="row.projectName"
                  width="260"
                  trigger="hover"
                  popper-class="project-popover"
                >
                  <div class="content">
                    <div>
                      策略名称：{{ row.productTagName }} — {{ row.strategyName }}
                    </div>
                    <div>项目描述：<div class="overflow" v-html="row.projectDesc.replace(/<img[^>]*>/g, '')" /></div>
                  </div>
                  <svg-icon slot="reference" style="font-size: 16px; color: #c8c8c8;" name="el-icon-warning" />
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="publishAt" label="时间" align="center" />
          <el-table-column
            prop="projectStatusDesc"
            align="center"
            label="状态"
            class-name="status-col"
          >
            <template slot-scope="{row}">
              <span v-if="row.projectStatusDesc" :style="`color: ${row.projectStatus === 1 ? '#30C691' : row.projectStatus === 2 ? '#FF7600' : '#969696'};`">{{ row.projectStatusDesc }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="projectTargetTypeDesc"
            label="项目内容"
            align="center"
          />
          <el-table-column
            prop="teamCount"
            align="center"
            label="执行团队人数"
          />
          <el-table-column
            prop="activityCount"
            align="center"
            label="活动数量"
          />
          <el-table-column
            prop="targetUserCount"
            align="center"
            label="目标客户数"
          />
          <el-table-column
            prop="targetCount"
            align="center"
            label="支出/总预算"
          >
            <template slot-scope="{row}">
              {{ row.usedBudget }}/{{ row.budget }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createAt"
            align="center"
            width="180px"
            label="项目进度"
          >
            <template slot-scope="{ row: {executedCount,targetCount,achievementRate } }">
              <el-tooltip
                popper-class="customize-el-tooltip"
                placement="right"
                :open-delay="300"
                effect="light"
              >
                <template slot="content">
                  已执行：{{ executedCount }}<span style="margin-right: 10px;" />项目推广目标：{{ targetCount }}
                </template>
                <span style="display: flex; align-items: center; gap: 10px;">
                  <el-progress
                    style="flex: 1;"
                    color="#30C691"
                    :stroke-width="8"
                    :show-text="false"
                    define-back-color="#E1E1E1"
                    :percentage="achievementRate > 100 ? 100: achievementRate"
                  />
                  <span v-if="achievementRate" style="width: 35px; text-align: right;">{{ achievementRate }}%</span>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            class-name="score"
            align="center"
            label="得分"
            width="150"
          >
            <template v-if="!!(!row.targetScore && row.targetScoreVOS&&!row.targetScoreVOS.length)" slot-scope="{row}">
              <Chart
                :title-text="chartBind(row).titleText"
                :y-data="chartBind(row).yData"
                :x-data="chartBind(row).xData"
                :ref-key="chartBind(row).refKey"
                :item-stylye-color="chartBind(row).itemStylyeColor"
              />
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            class-name="control-btn"
            label="操作"
            width="130"
          >
            <template slot-scope="{row}">
              <el-button
                v-if="![-1].includes(row.projectStatus)"
                type="text"
                size="mini"
                @click="$router.push({name: 'ProjectStaegicDetail', query: {id: row.projectId}})"
              >
                查看
              </el-button>
              <template v-if="row.isMyCreate">
                <el-button
                  type="text"
                  size="mini"
                  style="margin-left: 10px;"
                  @click="onJumpDetail(row)"
                >
                  编辑
                </el-button>
                <el-button
                  v-if="[0, 2].includes(row.projectStatus)"
                  type="text"
                  size="mini"
                  style="margin-left: 10px;"
                  @click="onSwitchProjectStatus(row)"
                >
                  开启
                </el-button>
                <el-button
                  v-if="[1].includes(row.projectStatus)"
                  type="text"
                  size="mini"
                  style="color: #e8312f;"
                  @click="onSwitchProjectStatus(row)"
                >
                  结束
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <el-pagination
          style="margin-top: 20px;"
          :current-page="pagination.pageNo"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          :page-sizes="pagination.sizes"
          :layout="pagination.layout"
          :hide-on-single-page="false"
          :small="pagination.small"
          class="pagination"
          background
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        />
      </el-tabs>
    </page-main>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import Chart from './components/chart.vue'
export default {
  name: 'ProjectStategic',
  components: {
    Chart
  },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      searchParams: {
        strategyIds: [],
        productId: '',
        projectStatus: '',
        projectName: ''
      },
      tableData: [],
      stategicList: [],
      tabList: [
        {
          name: '全部',
          key: 'all'
        },
        {
          name: '进行中',
          key: '1'
        },
        {
          name: '已结束',
          key: '2'
        },
        {
          name: '草稿',
          key: '-1'
        }
      ],
      tagGroup: [],
      productList: [],
      spanArr: [],
      currentIndex: ''
    }
  },
  computed: {
    tabsValue() {
      return this.searchParams.projectStatus === '' ? 'all' : this.searchParams.projectStatus
    }
  },
  mounted() {
    this.getStategicList()
    this.getDataList()
    this.getTagGroup().then(res => {
      const row = res.find(item => item.groupName.includes('产品'))
      this.getProdectList(row.id)
    })

    this.$EventBus.$on('refreshProjectList', () => {
      this.getDataList()
    })
  },
  methods: {
    async getDataList() {
      this.loading = true
      this.spanArr = []
      try {
        const params = this.getParams(this.searchParams)
        const {
          data: { data, totalCount }
        } = await this.$axios.post(this.$API.projectList, params)
        this.tableData = this.dealData(data) || []
        this.rowspan()
        this.pagination.total = totalCount || 0
      } catch (error) {
        console.log(error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    },
    // 获取策略列表
    async getStategicList() {
      try {
        const params = {
          status: 1,
          pageNo: 1,
          pageSize: 999999
        }
        const { data: {
          data
        } } = await this.$axios.get(this.$API.strategyList, {params})
        this.stategicList = data ?? []
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      }
    },
    onJumpDetail(row) {
      this.$router.push({
        name: 'ProjectStaegicEditCreate',
        query: {
          id: row.projectId
        }
      })
    },
    onChange(com) {
      this.searchParams.projectStatus = com.name === 'all' ? '' : com.name
      this.onSearch()
    },
    // 关闭项目
    async onSwitchProjectStatus(row) {
      if (row.projectStatus === 1) {
        await this.$MessageDialog({
          message: '确定结束项目么？结束后项目预算不可被使用。'
        })
      }
      try {
        const params = {
          projectId: row.projectId,
          projectStatus: row.projectStatus === 1 ? 2 : 1
        }
        const {data} = await this.$axios.get(this.$API.changeProjectStatus, {params})
        if (data) {
          this.$msg.success('操作成功')
          this.getDataList()
        }
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      }
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup)
      if (data.length) {
        this.tagGroup = data
      }
      return data
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId
      }
      const { data } = await this.$axios.get(this.$API.tagList, { params })
      if (data.length) {
        this.productList = data
      }
    },
    chartBind(row) {
      const {projectTargetType, targetScore = null, targetScoreVOS = []} = row
      const obj = {
        refKey: `${row.projectId}_${ row.projectTargetType}`,
        xData: this.getChartData(targetScoreVOS, 'dateStr'),
        yData: this.getChartData(targetScoreVOS, 'targetScore')
      }
      if (projectTargetType === 1) {
        obj.titleText = ![0, null].includes(targetScore) ? `观念得分:${targetScore}` : ''
        obj.itemStylyeColor = '#3D61E3'
      } else if (projectTargetType === 2) {
        obj.titleText = ![0, null].includes(targetScore) ? `行为得分:${targetScore}` : ''
        obj.itemStylyeColor = '#AE3DE3'
      }
      return obj
    },
    rowspan() {
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          this.spanArr.push(1)
          this.position = 0
        } else {
          if (this.tableData[index].projectId === this.tableData[index - 1].projectId) {
            this.spanArr[this.position] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.position = index
          }
        }
      })
    },
    objectSpanMethod({rowIndex, columnIndex }) {  // 表格合并行
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      } else if (columnIndex === 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      } else if (columnIndex === 2) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      } else if (columnIndex === 10) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    dealData(data) {
      return data.map(item => {
        if (item.targetTypeVOS?.length) {
          return item.targetTypeVOS?.map(targetTypeVO => {
            return {
              ...item,
              ...targetTypeVO,
              rowspan: item.targetTypeVOS?.length || 0
            }
          })
        } else {
          return [item]
        }
      }).flat(2).filter(v => v)
    },
    getChartData(targetScoreVOS, type) {
      return targetScoreVOS.length ? targetScoreVOS.map(item => item[type]) : []
    },
    handleCellMouseEnter(row) { // 鼠标移入后赋值
      this.currentIndex = row.projectId
    },
    handleCellMouseLeave() { // 鼠标移走后置空
      this.currentIndex = ''
    },

    // 行的颜色设置
    tableRowClassName({ row }) {
      const flag = row?.projectId == this.currentIndex
      return flag ? 'quotatemplate-my-hover-row' : ''
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.project-stategic {
  .content {
    padding-top: 0;
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    .search-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
    .right-btn-wrapper {
      .el-button {
        padding: 8px 12px;
      }
    }
  }
  .project-name {
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    gap: 5px;
    >.title {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    >span:not(.title) {
      margin-top: 3px;
    }
  }
  ::v-deep .score {
    padding: 0;
    .cell {
      padding: 0;
    }
  }
  ::v-deep .custom-table {
    .quotatemplate-my-hover-row {
      background: #f4f6fa !important;
    }
    .status-col {
      border-right: 1px solid #ebeef5;
    }
    .control-btn {
      border-left: 1px solid #ebeef5;
    }
    &.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: transparent;
    }
  }
}
</style>

<style lang="scss">
.project-popover {
  .el-popover__title {
    font-size: 12px;
    font-weight: bold;
    color: #0f0f0f;
  }
  .content {
    font-size: 12px;
    >div {
      margin-top: 5px;
      color: #5a5a5a;
      line-height: 20px;
      .overflow {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>

<template>
  <page-main title="项目管理 - 新建项目" back class="create">
    <template slot="top">
      <steps :value="form.verifyStep" :list="['1.项目基础信息', '2.选择执行组织及目标客户', '3.设置团队目标及预算']" />
    </template>
    <div v-loading="loading" style="padding-top: 13px;">
      <Step1
        v-if="form.verifyStep === 1"
        ref="step1"
        v-model="form"
        :budget-i18n="budgetI18n"
      />
      <Step2
        v-if="form.verifyStep === 2"
        ref="step2"
        v-model="form"
        :budget-i18n="budgetI18n"
      />
      <Step3
        v-if="form.verifyStep === 3"
        ref="step3"
        v-model="form"
        :budget-i18n="budgetI18n"
      />
    </div>
    <fixed-action-bar class="fixed-bar">
      <el-button plain @click="$router.back()">取消</el-button>
      <el-button
        v-if="form.projectStatus === -1"
        :loading="loading"
        plain
        @click="onSave(true)"
      >
        保存
      </el-button>
      <el-button v-if="form.verifyStep !== 1" type="primary" @click="onPrev">上一步</el-button>
      <el-button v-if="form.verifyStep !== 3" type="primary" @click="onNext">下一步</el-button>
      <el-button
        v-if="form.verifyStep === 3"
        type="primary"
        :loading="loading"
        @click="onSubmit(false)"
      >
        保存并发布
      </el-button>
    </fixed-action-bar>
  </page-main>
</template>

<script>
import { deepClone } from '@/utils/util'
import Step1 from './components/step1'
import Step2 from './components/step2'
import Step3 from './components/step3'
export default {
  name: 'ProjectStaegicEditCreate',
  components: {
    Step1,
    Step2,
    Step3
  },
  data() {
    return {
      form: {
        projectId: '',
        saveSketch: false,
        verifyStep: 1,
        projectStatus: -1,
        projectName: '',
        projectDesc: '',
        budgetList: [],
        strategyId: ''
      },
      budgetI18n: [
        {
          label: '观念覆盖',
          value: 1
        }, {
          label: '播客培养',
          value: 2
        }
      ],
      loading: false
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail()
    }
  },
  methods: {
    async onSubmit(saveSketch) {
      try {
        this.loading = true
        await this.$refs[`step${this.form.verifyStep}`].submit()
        const params = deepClone(this.form)
        params.budgetList = params.budgetList.map(budget => {
          budget.execOrgIds = budget.execOrgIds.map(execOrg => {
            return {
              orgProjectTargetBudget: execOrg.orgProjectTargetBudget,
              orgId: execOrg.orgId,
              targetCount: execOrg.targetCount
            }
          })
          budget.targetUserList = budget.targetUserList.map(targetUser => {
            return {
              userId: targetUser.id,
              userType: 1
            }
          })
          return budget
        })
        params.saveSketch = saveSketch
        const res = await this.$axios.post(this.$API.createOrEditProject, params)

        if (res.data) {
          if (!saveSketch) {
            this.$msg.success('保存成功')
            this.$EventBus.$emit('refreshProjectList')
            this.$router.back()
          } else {
            if (this.form.projectId !== res.data) {
              this.form.projectId = res.data
            }
          }
        }
        return res
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
        return Promise.reject(error)
      } finally {
        this.loading = false
      }
    },
    async onPrev() {
      if (this.form.verifyStep > 1) {
        this.form.verifyStep--
      }
    },
    async onNext() {
      try {
        await this.$refs[`step${this.form.verifyStep}`].submit()
        if (this.form.verifyStep < 3) {
          if (!this.$route.query.id) {
            this.onSubmit(true)
          }
          this.form.verifyStep++
        }
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async onSave(saveSketch) {
      try {
        await this.onSubmit(saveSketch)
        this.$msg.success('保存成功')
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async getDetail() {
      try {
        const params = {
          projectId: this.$route.query.id
        }
        this.loading = true
        const {data} = await this.$axios.get(this.$API.getProjectEditDetail, {params})
        data.verifyStep = data.verifyStep || 1
        data.budgetList = data?.budgetList.map(budgetRow => {
          budgetRow.execOrgIds = budgetRow.execOrgIds || []
          budgetRow.targetUserList = budgetRow.targetUserList || []
          return budgetRow
        })
        this.form = data
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.create {
  .fixed-bar {
    .el-button {
      min-width: 100px;
      height: 30px;
      border-radius: 3px;
    }
  }
}
</style>

<template>
  <div class="step2">
    <el-form
      ref="form"
      :rules="formRules"
      :model="actBudget"
      label-position="right"
      label-width="140px"
      label-suffix="："
      hide-required-asterisk
    >
      <el-form-item label="选择项目执行团队" prop="execOrgIds">
        <el-button type="info" plain @click="treeDialogVisible = true">添加组织 <svg-icon name="el-icon-plus" /></el-button>
        <el-table style="margin-top: 15px; max-width: 800px;" class="customize-el-table" :data="execOrgList || []">
          <el-table-column prop="orgName" label="名称" />
          <el-table-column prop="representCount" label="执行团队人数" />
          <el-table-column prop="name" width="120px" label="操作">
            <template slot-scope="scoped">
              <el-button type="text" style="color: #e8312f;" @click="onDelTeamData(scoped.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="选择目标客户" class="target-customer" prop="targetUserList">
        <div class="tab-switch">
          <el-tag
            v-for="item in form.budgetList"
            :key="item.projectTargetType"
            size="small"
            :type="actProjectTargetType === item.projectTargetType ? 'pirmary' : 'info'"
            @click="selectBudgetRow(item.projectTargetType)"
          >
            {{ budgetRow(item.projectTargetType).label }}客户
          </el-tag>
        </div>
        <el-radio-group
          :value="actBudget.customerType"
          class="radio-group"
          @input="(val) => $set(actBudget, 'customerType', val)"
          @change="actBudget.targetUserList = []"
        >
          <el-radio :label="1">全部客户（所有绑定客户均可参加！）</el-radio>
          <el-radio :label="2">
            <span>自定义添加 <el-button
              type="info"
              plain
              style="margin-left: 10px;"
              :disabled="actBudget.customerType !== 2"
              @click="customerDialogVisible = true"
            >添加客户 <svg-icon name="el-icon-plus" /></el-button></span>
          </el-radio>
        </el-radio-group>
        <div class="table-controls">
          <el-button
            type="text"
            :disabled="actBudget.customerType !== 2"
            @click.stop="importDialog = true"
          >
            <svg-icon name="outline-library-add" /> 批量导入客户
          </el-button>
        </div>
        <el-table
          style="margin-top: 20px; max-width: 800px;"
          stripe
          max-height="500px"
          class="customize-el-table"
          row-key="id"
          :data="actBudget.targetUserList"
        >
          <el-table-column prop="userName" label="姓名" />
          <el-table-column prop="titleName" label="所属职称" />
          <el-table-column prop="hospital" label="医院" />
          <el-table-column prop="departmentName" label="所在科室" />
          <el-table-column v-if="actProjectTargetType === 2" prop="orgGradeName" label="播客企业级别" />
          <el-table-column v-if="actProjectTargetType === 2" prop="platformGradeName" label="播客平台级别" />
          <el-table-column width="60px" label="操作">
            <template slot-scope="scoped">
              <el-button type="text" style="color: #e8312f;" @click="onDelCustomerData(scoped.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <!-- 添加客户 -->
    <add-customer
      v-model="customerDialogVisible"
      :strategy-id="form.strategyId"
      :act-budget.sync="actBudget"
      :project-target-type="actBudget.projectTargetType"
    />

    <!-- 添加组织 -->
    <el-dialog
      :visible="treeDialogVisible"
      class="customize-el-dialog tree-dialog"
      width="620px"
      title="添加组织"
      @close="treeDialogVisible = false"
      @open="handleOpenTree"
    >
      <!-- <div class="header">
        <span>全部共{{ form.number }}个地区部门</span>
      </div> -->
      <el-tree
        ref="tree"
        v-loading="treeLoading"
        check-on-click-node
        :expand-on-click-node="false"
        class="tree-list"
        :data="treeData"
        show-checkbox
        default-expand-all
        node-key="orgId"
        :props="defaultProps"
        :check-strictly="true"
      />
      <div slot="footer" style="padding-top: 20px; border-top: 1px solid #eee;">
        <el-button type="primary" plain @click="treeDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="selectOrg">确认</el-button>
      </div>
    </el-dialog>

    <!-- 批量上传 -->
    <CommonImportExcelDialog
      v-model="importDialog"
      :close-on-click-modal="true"
      :down-fn="downFn"
      :upload-data="{speakerValid: actBudget.projectTargetType === 2}"
      :upload-api="$API.batchImportProjectDoctorModelFile"
      :confirm-api="$API.excelResultData"
      :confirm-params="{strategyId: form.strategyId}"
      @onConfirm="onConfirm"
    />
  </div>
</template>

<script>
import CommonImportExcelDialog from '@/baseComponents/CommonImportExcelDialog'
import addCustomer from './add-customer.vue'
import { deepClone } from '@/utils/util'
export default {
  name: 'Step2',
  components: { addCustomer, CommonImportExcelDialog },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    },
    budgetI18n: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const validateTargetUserList = (rule, value, callback) => {
      const row = this.form.budgetList?.find(item => {
        if (item.customerType === 2) {
          return item.targetUserList?.length === 0
        }
      })
      if (!row) {
        const flag = this.form.budgetList?.find(item => item.customerType === null)
        if (flag) {
          callback(new Error('请选择客户'))
        } else {
          callback()
        }
      } else {
        const {label} = this.getBudget('value', row.projectTargetType)
        if (row.projectTargetType !== this.actProjectTargetType && label) {
          callback(new Error(`请在${label}中添加客户`))
        }
        callback(new Error('请添加客户'))
      }
    }
    const validateExecOrgIds = (rule, value, callback) => {
      if (value?.length === 0) {
        callback(new Error('请选择执行团队'))
      } else {
        callback()
      }
    }

    return {
      treeDialogVisible: false,
      customerDialogVisible: false,
      importDialog: false,
      formRules: {
        execOrgIds: [{ required: true, type: 'array', validator: validateExecOrgIds, message: '请选择执行团队', trigger: 'change' }],
        targetUserList: [{required: true, type: 'array', validator: validateTargetUserList, trigger: 'change'}]
      },
      actProjectTargetType: '',
      treeLoading: false,
      treeData: [],
      defaultProps: {
        children: 'child',
        label: 'orgName'
      },
      actBudget: {},
      execOrgList: []
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    // 初始化 actProjectTargetType
    'form.budgetList.length': {
      handler(val) {
        let targetType = ''
        if (val === 1) {
          targetType = this.form.budgetList[0].projectTargetType
        } else {
          targetType = 1
        }
        this.selectBudgetRow(targetType)
      },
      immediate: true
    },
    // 更新 actBudget 时同步到 this.form.budgetList 中
    actBudget: {
      handler(val) {
        if (val && this.actProjectTargetType !== '') {
          this.form.budgetList = this.form.budgetList.map(item => {
            if (item.projectTargetType === this.actProjectTargetType) {
              return val
            }
            return item
          })
        }
      },
      deep: true
    },
    'execOrgList': {
      handler(val) {
        this.form.budgetList = this.form.budgetList.map(budget => {
          budget.execOrgIds = deepClone(val).map(item => {
            const row = budget.execOrgIds.find(execOrg => execOrg.orgId === item.orgId)
            return {
              orgProjectTargetBudget: 0,
              targetCount: 0,
              ...row,
              ...item
            }
          })
          return budget
        })
      },
      deep: true
    }
  },
  created() {
    if (this.form.budgetList) {
      this.execOrgList = this.form.budgetList[0]?.execOrgIds?.map(item => {
        return {
          orgId: item.orgId,
          orgName: item.orgName
        }
      }) || []
    }
  },
  mounted() {
    this.getTreeList()
    this.orgRepCount(this.execOrgList?.map(item => item.orgId).filter(v => v))
  },
  methods: {
    async getTreeList() {
      try {
        this.treeLoading = true
        const {data} = await this.$axios.get(this.$API.v1TreeList)
        this.treeData = [data] || []
      } catch (error) {
        console.log('error: ', error)
      } finally {
        this.treeLoading = false
      }
    },
    onDelTeamData(row) {
      this.execOrgList = this.execOrgList.filter(item => item.orgId !== row.orgId)
    },
    onDelCustomerData(row) {
      this.actBudget.targetUserList.forEach(item => {
        if (item.id === row.id) {
          this.actBudget.targetUserList.splice(this.actBudget.targetUserList.indexOf(item), 1)
        }
      })
    },
    budgetRow(val) {
      return this.budgetI18n.find(item => item.value === val) || {}
    },
    selectOrg() {
      const list = this.$refs.tree.getCheckedNodes()
      this.execOrgList = list?.map(item => {
        return {
          orgId: item.orgId,
          orgName: item.orgName,
          representCount: item.representCount
        }
      })
      this.orgRepCount(list?.map(item => item.orgId).filter(v => v))
      this.treeDialogVisible = false
    },
    selectBudgetRow(projectTargetType) {
      this.actProjectTargetType = projectTargetType
      this.actBudget = this.form.budgetList.find(item => item.projectTargetType === this.actProjectTargetType)
    },
    getBudget(key, value) {
      const row = this.budgetI18n.find(item => item[key] === value) ?? {}
      return row
    },
    async orgRepCount(orgIds) {
      try {
        if (orgIds.length === 0) return
        const params = {
          orgIds,
          doctorIds: []
        }
        const {data} = await this.$axios.post(this.$API.factoryDoctorRepresentCount, params)
        this.execOrgList = this.execOrgList?.map(item => {
          const row = data.find(row => row.factoryId === item.orgId)
          if (row) {
            this.$set(item, 'representCount', row.representCount)
          }
          return item
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },
    handleOpenTree() {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(this.execOrgList?.map(item => item.orgId))
      })
    },
    // 批量导入
    onConfirm(res) {
      this.actBudget.targetUserList = res?.data?.results?.map(item => {
        return {
          userName: item.doctorName,
          titleName: item.title,
          hospital: item.hospital,
          departmentName: item.department,
          orgGradeName: item.orgGradeName,
          platformGradeName: item.platformGradeName,
          id: item.doctorId
        }
      })
    },
    // 批量导入模板下载
    async downFn() {
      try {
        const {data} = await this.$axios.post(this.$API.modelFile)
        this.$msg.success(data)
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      }
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(this.form)
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.step2 {
  .target-customer {
    .tab-switch {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
      .el-tag {
        border-radius: 1px;
        cursor: pointer;
      }
    }
    .radio-group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 700px;
    }
    .table-controls {
      display: flex;
      justify-content: flex-end;
      width: 800px;
      position: relative;
      .el-button {
        position: absolute;
        top: -20px;
      }
    }
  }
  ::v-deep .tree-dialog {
    .header {
      font-size: 12px;
      color: #0f0f0f;
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 10px;
    }
    .tree-list {
      height: 350px;
      overflow-y: scroll;
      .el-tree-node .el-tree-node__content {
        .el-icon-caret-right {
          width: 13px;
          height: 13px;
          border: 1px solid #c0c4cc;
          position: relative;
          margin: 6px;
          &::before {
            content: "";
            width: 1px;
            height: calc(100% - 2px);
            background-color: #c0c4cc;
            top: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }
          &::after {
            content: '';
            width: calc(100% - 2px);
            height: 1px;
            background-color: #c0c4cc;
            top: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }
        }
        .el-tree-node__expand-icon.expanded {
          &::after {
            opacity: 0;
          }
        }
        .el-tree-node__expand-icon.is-leaf {
          opacity: 0;
        }
        .el-checkbox {
          order: 1;
        }
        .el-tree-node__label {
          flex: 1;
        }
      }
    }
  }
}
</style>

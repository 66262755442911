<template>
  <div class="detail">
    <page-main back :title="`项目管理 - ${detail.projectName}`">
      <div v-loading="loading" class="top-info">
        <div class="title">{{ detail.projectName }} <el-tag :type="tagType">{{ detail.projectStatusDesc }}</el-tag></div>
        <el-form label-suffix="：" class="info-form">
          <el-form-item label="项目时间">{{ formatStartAndEndTime(detail.publishAt) }}</el-form-item>
          <el-form-item
            label="产品策略"
          >
            {{ detail.productTagName }} — {{ detail.strategyName }}
          </el-form-item>
          <el-form-item
            label="项目描述"
          >
            <span v-if="detail.projectDesc" style="display: flex;">
              <div class="overflow" v-html="detail.projectDesc.replace(/<img[^>]*>/g, '')" />
              <el-button type="text" @click="showAllProjectDesc = true">
                查看详情
              </el-button>
            </span>
          </el-form-item>
        </el-form>
      </div>
    </page-main>
    <page-main class="customize-page-main">
      <el-tabs v-model="activeTab" class="customize-el-tabs">
        <template v-for="item in tabPaneList">
          <el-tab-pane
            v-if="item.show"
            :key="item.name"
            :label="item.label"
            :name="item.name"
          >
            <component :is="item.name" v-if="activeTab === item.name" :detail="detail" />
          </el-tab-pane>
        </template>
      </el-tabs>
    </page-main>

    <el-dialog
      title="项目描述"
      :visible.sync="showAllProjectDesc"
      class="customize-el-dialog project-rich"
    >
      <div class="content-html" v-html="detail.projectDesc" />
      <div slot="footer">
        <el-button type="primary" @click="showAllProjectDesc = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ProjectExecution from './components/project-execution.vue'
import ConceptualOverlay from './components/conceptual-overlay.vue'
import SpeakerTraining from './components/speaker-training.vue'
export default {
  name: 'ProjectStaegicDetail',
  components: {
    ProjectExecution,
    ConceptualOverlay,
    SpeakerTraining
  },
  data() {
    return {
      detail: {
        projectName: ''
      },
      loading: true,
      activeTab: 'project-execution',
      showAllProjectDesc: false
    }
  },
  computed: {
    shouldShowFullProjectDescription() {
      return this.detail.projectDesc?.length > 100 || this.showAllProjectDesc
    },
    tagType() {
      switch (this.detail.projectStatus) {
        case 1:
          return ''
        case 2:
          return 'error'
        default:
          return ''
      }
    },
    tabPaneList() {
      const {isProjectCreator, isProjectExecOrg, targetTypes} = this.detail
      const arr = [
        {
          label: '项目执行',
          name: 'project-execution',
          show: isProjectCreator || isProjectExecOrg
        },
        {
          label: '观念覆盖',
          name: 'conceptual-overlay',
          show: targetTypes?.includes(1)
        },
        {
          label: '播客培养',
          name: 'speaker-training',
          show: targetTypes?.includes(2) && (isProjectCreator || isProjectExecOrg)
        }
      ]
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.activeTab = arr.find(item => item.show)?.name || ''
      return arr
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      try {
        this.loading = true
        const params = {
          projectId: this.$route.query.id
        }
        const {data} = await this.$axios.get(this.$API.detailBaseInfo, {params})
        this.detail = data
      } catch (error) {
        console.log('error: ', error)
        this.$msg.error(error?.message)
      } finally {
        this.loading = false
      }
    },
    formatStartAndEndTime(startTime, endTime) {
      if (!startTime) {
        return
      }
      // 解析传入的日期时间
      const startMoment = this.$moment(startTime, 'YYYY-MM-DD')
      // 开始日期总是需要年份
      let formattedRange = startMoment.format('YYYY.MM.DD')

      // 如果endTime不为空且有效，则需要格式化结束日期
      if (endTime) {
        const endMoment = this.$moment(endTime, 'YYYY-MM-DD')
        if (startMoment.year() === endMoment.year()) {
          // 同一年，则省略结束日期的年份
          formattedRange += ` — ${endMoment.format('MM.DD')}`
        } else {
          // 不同年，则加上完整的结束日期
          formattedRange += ` — ${endMoment.format('YYYY.MM.DD')}`
        }
      }

      // 返回格式化后的字符串
      return formattedRange
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.detail {
  .top-info {
    padding: 10px;
    .title {
      font-weight: 500;
      font-size: 12px;
      color: #0f0f0f;
      margin-bottom: 10px;
      .el-tag {
        margin-left: 5px;
        border-radius: 30px;
      }
    }
    .info-form {
      .el-form-item {
        font-size: 12px;
        margin-bottom: 0;
        color: #787878;
        line-height: 14px;
        .el-form-item__label {
          font-size: 12px;
          color: #787878;
          line-height: 14px;
        }
      }
    }
  }
  ::v-deep .el-tabs__content {
    overflow: unset;
    .header-controls {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 5px;
      .left {
        font-size: 12px;
        color: #969696;
        display: flex;
        gap: 20px;
      }
      .right {
        position: relative;
        top: -45px;
      }
    }
  }
  .project-rich {
    .content-html {
      max-height: 400px;
      overflow-y: scroll;
      padding: 10px;
      ::v-deep img {
        width: 100% !important;
        height: auto;
      }
    }
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      padding-top: 20px;
    }
  }
  .overflow {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
